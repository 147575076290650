html,
body,
#root {
  height: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
    sans-serif;
  color: #212F49;
  line-height: 24px;
}

body.no-footer .prequal-wrapper {
  padding-bottom: 0 !important;
}

a {
  font-size: 14px;
  color: #00a0ff;
}

a:hover {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
    sans-serif;
}

h1 {
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
    sans-serif;
  font-size: 36px;
  font-weight: normal;
  color: #212f49;
}

small {
  line-height: 12px;
  font-size: 12px;
}

_::-webkit-full-page-media,
_:future,
:root .safari-spacing-fix:not(*:root) {
  padding-top: 0 !important;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
