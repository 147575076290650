/* Chilipiper */
.chilipiper-popup {
    z-index: 1000000000 !important;
}
#chilipiper-wrapper .chilipiper-popup {
    background: white;
    align-items: baseline;
}
.chilipiper-popup.in-custom-element .fabe-availability-mobile {
    background: none;
}
.chilipiper-popup .chilipiper-popup-window {
    width: 100% !important;
    max-width: 850px;
    padding: 10px 10px 0 10px;
}
[data-chili-css="ModalCloseButton"], .chilipiper-popup .routing-loader {
    display: none !important;
}
@media (max-width: 960px) {
    .chilipiper-popup .chilipiper-popup-window {
        padding: 0;
    }
    #chilipiper-wrapper {
        height: 1200px !important;
    }
    .chilipiper-popup {
        padding-top: 0;
    }
    .chilipiper-popup iframe {
        height: 1200px !important;
    }
}
